'use client';
import { Amplify } from 'aws-amplify';
import React, { ReactNode } from 'react';

export default function AmplifyProvider({ children }: { children: ReactNode }) {
  
  Amplify.configure({
    Auth: {
      Cognito: {
        userPoolId: process.env.NEXT_PUBLIC_USER_POOL_ID!,
        userPoolClientId: process.env.NEXT_PUBLIC_USER_POOL_CLIENT_ID!,
        identityPoolId: process.env.NEXT_PUBLIC_IDENTITY_POOL_ID!,
      },
    },
    API: {
      REST: {
        api: {
          endpoint: process.env.NEXT_PUBLIC_API_ENDPOINT!,
        },
      },
    },
  });

  return <>{children}</>;
}